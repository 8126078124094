.logoRow {
    display: flex;
    justify-content: center;
    margin: 24px 0px;
}

.buttonRow {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}
