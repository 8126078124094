html {
    height: 100vh;
    overflow: hidden;
}
body {
    all: unset;
    height: 100vh;
    font-family: Inter, Arial, Helvetica, sans-serif;
    /* background-color: #f6f8fa; */
}
@font-face {
    font-family: Inter;
    src: local(Inter-Light), url(./app/assets/fonts/Inter-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: Inter;
    src: local(Inter-Italic), url(./app/assets/fonts/Inter-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
}
@font-face {
    font-family: Inter;
    src: local(Inter-Regular), url(./app/assets/fonts/Inter-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: Inter;
    src: local(Inter-Medium), url(./app/assets/fonts/Inter-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: Inter;
    src: local(Inter-SemiBold), url(./app/assets/fonts/Inter-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}
