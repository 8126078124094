
.form-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 15px;
}

.form-container > div {
    display: flex;
    flex-direction: column;
}

.middle-div-container {
    display: flex;
}

.middle-div-container > div {
    margin-left: auto;
    margin-right: auto;
}

.table-container {
    overflow-x: scroll;
    margin-top: 15px;
}
