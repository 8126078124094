.systemic-logo {
    margin: 20px;
    width: calc(var(--sidebar-width) - 40px);
}

html {
    width: 100%;
    overflow-x: hidden;
}

body {
    width: 100%;
    overflow-x: hidden;
}

.page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 16px;
}

.desktop_only {
    display: flex;
}

@media screen and (max-width: 992px) {
    .desktop_only {
        display: none;
    }
}
