.avatarRow {
    display: flex;
    justify-content: center;
    margin: 24px 0px;
}

.avatar {
    border: 1px solid var(--secondary-default);
    border-radius: 4px;
    width: 128px;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-default);
}

.buttonRow {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}
