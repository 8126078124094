.logo {
    border: 1px solid var(--secondary-default);
    border-radius: 4px;
    width: 128px;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-default);
}

