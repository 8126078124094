.burger_button {
    cursor: pointer;
}

@keyframes drop-down {
  from {transform: translateY(calc(-100% - 1em));}
  to {transform: translateY(0px);}
}

.background {
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    position: absolute;

    &.closed {
        pointer-events: none;
    }
}

.container {
    transform: translateY(64px);
    overflow: hidden;
}


.dropdown {
    overflow: hidden;
    max-width: 800px;
    background-color: var(--neutral-10);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: calc(100vw - 2em);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    transform: translateY(0px);

    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 1em;

    transition: transform 0.25s ease-in;
    animation: drop-down 0.25s ease-out;

    &.closed {
        transform: translateY(calc(-100% - 1em));
    }
}

.link {
    display: flex;
    padding: 1em;
    align-items: center;
    gap: 1em;
    cursor: pointer;

    &:hover {
        background-color: var(--secondary-surface);
    }

    &.active {
        background-color: rgba(26, 122, 187, 0.18);
        // background-color: var(--primary-active);
    }
}



.separator {
    display: flex;
    height: 1px;
    background-color: var(--neutral-30);
}
