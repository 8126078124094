.header-content {
    display: flex;
    flex-direction: column;
    color: white;

    font-size: medium;
    font-weight: 1;

    gap: 8px;

    .links {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
}
