.login-page {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-rect {
    display: flex;
    min-width: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: solid 1px var(--neutral-50);
    background-color: var(--neutral-20);
    padding: 50px;
}

.login-rect > * {
    width: 100%;
}

.login-title {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    padding-bottom: 10px;
}

.error-message {
    height: 15px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    color: var(--danger-default);
}
