.middle-div-container {
    display: flex;
}

.middle-div-container > div {
    margin-left: auto;
    margin-right: auto;
}

.table-container {
    overflow-x: scroll;
    margin-top: 15px;
}
