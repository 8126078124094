.banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--top-bar-dark-background);

    .top_row {
        .top_left_row {
            display: flex;
            align-items: center;
            gap: 16px;

            .title {
                color: var(--neutral-90);
            }
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--neutral-20);
        border-bottom: 1px solid var(--neutral-40);
        height: 64px;
        padding-left: 20px;
        padding-right: 10px;
    }

    .bottom_row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border-top-left-radius: 10px;
        // border-bottom: 1px solid var(--neutral-40);
        // background-color: var(--neutral-30);
        background: var(--neutral-10);
        border-bottom: 1px solid var(--neutral-40);
        padding: 10px 24px;
    }
}

.logout_button {
    margin-top: auto;
    margin-bottom: auto;
}

.user_section {
    display: flex;
}

.user_details {
    color: var(--neutral-90);
    text-align: right;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 32px;
}

.collapse_sidebar {
    display: block;
}
.open_drawer {
    display: none;
}

@media screen and (max-width: 992px) {
    .logout_button {
        display: none;
    }
    .user_details {
        display: none;
    }
    .collapse_sidebar {
        display: none;
    }
    .open_drawer {
        display: block;
    }
}

@media screen and (max-width: 700px) {
    .breadcrumbs {
        display: none;
    }
}
