.title {
    color: var(--neutral-90);
    margin: 0;
    white-space: nowrap;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.sub_page {
    color: var(--primary-default);
}
